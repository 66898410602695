import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Divider,
  Dropdown,
  Card,
  Icon,
} from '@makeably/creativex-design-system';
import ChannelLogos from 'components/molecules/ChannelLogos';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { getAuthenticityToken } from 'utilities/requests';
import {
  editTaxonomyCompanyTaxonomyPath,
  newTaxonomyCompanyTaxonomyPath,
  toggleStateTaxonomyCompanyTaxonomyPath,
} from 'utilities/routes';
import {
  decamelize,
  titleize,
} from 'utilities/string';

const ruleProps = PropTypes.shape({
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  delimiter: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  ruleType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  brandIndicator: PropTypes.string,
  marketIndicator: PropTypes.string,
});

const propTypes = {
  channelLabelMap: PropTypes.objectOf(PropTypes.string).isRequired,
  rules: PropTypes.arrayOf(ruleProps),
};

const defaultProps = {
  rules: undefined,
};

const headers = [
  {
    key: 'market',
    label: 'Market Key/Position',
  },
  {
    key: 'brand',
    label: 'Brand Key/Position',
  },
  {
    key: 'delimiter',
    label: 'Delimiter',
  },
  {
    key: 'channels',
    label: 'Channels',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

async function toggleState(id) {
  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());

  await fetch(toggleStateTaxonomyCompanyTaxonomyPath(id), {
    method: 'POST',
    body: formData,
  });
}

async function onDeactivateRule(id) {
  await toggleState(id);
  addToast('Taxonomy Structure Successfully Deactivated');
  window.location.reload();
}

async function onActivateRule(id) {
  await toggleState(id);
  addToast('Taxonomy Structure Successfully Activated');
  window.location.reload();
}

function getLabel(key, value, channelLabelMap) {
  if (key === 'channels') {
    return channelLabelMap[value];
  }

  return titleize(decamelize(value));
}

function getOptions(key, rules, channelLabelMap) {
  const values = rules.reduce((vals, rule) => vals.concat(rule[key]), []);
  const sorted = [...new Set(values)].sort();
  return sorted.map((value) => ({
    label: getLabel(key, value, channelLabelMap),
    value,
  }));
}

function getChannels(channels, channelLabelMap) {
  const channelItems = channels.map((channel) => ({
    label: channelLabelMap[channel],
    value: channel,
  }));

  return {
    element: (
      <ChannelLogos channels={channelItems} shownMax={5} />
    ),
    value: '',
  };
}

function getStatus(status) {
  return {
    element: (
      <div>
        { status === 'active' && <Icon color="green" name="checkCircle" /> }
        { status === 'inactive' && <Icon color="red" name="xCircle" /> }
      </div>
    ),
    value: status,
  };
}

function getActions(id, status) {
  return {
    element: (
      <div className="u-flexRow u-gap-8">
        <Button
          label="Edit"
          url={editTaxonomyCompanyTaxonomyPath(id)}
          variant="tertiary"
        />
        { status === 'active' && (
          <Button
            label="Deactivate"
            variant="tertiary"
            onClick={() => onDeactivateRule(id)}
          />
        ) }
        { status === 'inactive' && (
          <Button
            label="Activate"
            variant="tertiary"
            onClick={() => onActivateRule(id)}
          />
        ) }
      </div>
    ),
    value: '',
  };
}

function getItems(rules, channelLabelMap) {
  return rules.map((rule) => setItemElement({
    id: { value: rule.id },
    actions: getActions(rule.id, rule.status),
    brand: { value: rule.brandIndicator ?? 'N/A' },
    channels: getChannels(rule.channels, channelLabelMap),
    delimiter: { value: rule.delimiter },
    market: { value: rule.marketIndicator ?? 'N/A' },
    status: getStatus(rule.status),
  }));
}

function TaxonomyRules({
  channelLabelMap,
  rules,
}) {
  const [ruleTypeFilter, setRuleTypeFilter] = useState(undefined);
  const [channelFilter, setChannelFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [filteredRules, setFilteredRules] = useState(rules);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);

  const ruleTypeOptions = getOptions('ruleType', rules, channelLabelMap);
  const channelOptions = getOptions('channels', rules, channelLabelMap);
  const statusOptions = getOptions('status', rules, channelLabelMap);
  const hasFilters = ruleTypeFilter || channelFilter || statusFilter;

  const clearFilterOptions = () => {
    setRuleTypeFilter(undefined);
    setChannelFilter(undefined);
    setStatusFilter(undefined);
  };

  useEffect(() => {
    const filtered = rules.filter(({
      ruleType, channels, status,
    }) => {
      if (ruleTypeFilter && ruleType !== ruleTypeFilter.value) return false;
      if (channelFilter && !channels.includes(channelFilter.value)) return false;
      if (statusFilter && status !== statusFilter.value) return false;

      return true;
    });

    setFilteredRules(filtered);
  }, [ruleTypeFilter, channelFilter, statusFilter]);

  useEffect(() => {
    setItems(getItems(filteredRules, channelLabelMap));
  }, [filteredRules]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    } else {
      setSortedItems(items);
    }
  }, [items, sort]);

  return (
    <Card>
      <div className="u-flexRow u-justifyBetween u-marginBottom-16">
        <div className="u-flexRow u-gap-16 u-alignEnd">
          <Dropdown
            disabled={ruleTypeOptions.length === 0}
            label="Rule Type"
            menuProps={{ size: 'medium' }}
            options={ruleTypeOptions}
            selected={ruleTypeFilter}
            size="small"
            onChange={(value) => setRuleTypeFilter(value)}
          />
          <Dropdown
            disabled={channelOptions.length === 0}
            label="Channel"
            menuProps={{ size: 'medium' }}
            options={channelOptions}
            selected={channelFilter}
            size="small"
            onChange={(value) => setChannelFilter(value)}
          />
          <Dropdown
            disabled={statusOptions.length === 0}
            label="Status"
            menuProps={{ size: 'medium' }}
            options={statusOptions}
            selected={statusFilter}
            size="small"
            onChange={(value) => setStatusFilter(value)}
          />
          { hasFilters && (
            <Button
              label="Clear"
              variant="tertiary"
              onClick={() => clearFilterOptions()}
            />
          ) }
        </div>
        <AddNewButton
          label="Add New"
          url={newTaxonomyCompanyTaxonomyPath()}
        />
      </div>
      <Divider />
      <ItemsTable
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={(p) => setPage(p)}
        onSortChange={(value) => setSort(value)}
      />
      { filteredRules.length === 0 && (
        <div className="u-flexRow u-justifyCenter u-marginTop-16 t-empty">
          There are no available taxonomy structures
        </div>
      ) }
    </Card>
  );
}

TaxonomyRules.propTypes = propTypes;
TaxonomyRules.defaultProps = defaultProps;

export default TaxonomyRules;
