import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Tag,
} from '@makeably/creativex-design-system';
import { typeLabels } from 'components/reporting/shared';
import creativeLifecycleSvg from 'images/creativeLifecycleReport.svg';
import guidelineReportSvg from 'images/guidelineReport.svg';
import creativeQualitySvg from 'images/scoreReport.svg';
import timeBreakdownSvg from 'images/timeBreakdownReport.svg';
import { useViewPage } from 'utilities/mixpanel';
import { newReportingReportPath } from 'utilities/routes';
import styles from './SelectType.module.css';

const propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const reportTypeData = {
  ScoreReport: {
    description: 'Uncover insights from your creative data in real time using segments and metrics.',
    label: typeLabels.ScoreReport,
    svg: creativeQualitySvg,
  },
  TimePeriodReport: {
    description: 'Identify how your data changes over time using halves, quarters and months.',
    isBeta: true,
    label: typeLabels.TimePeriodReport,
    svg: timeBreakdownSvg,
  },
  GuidelineReport: {
    description: 'Assess which guidelines are being met or missed to identify areas of improvement.',
    isBeta: true,
    label: 'Guidelines',
    svg: guidelineReportSvg,
  },
  CreativeLifecycleReport: {
    description: 'Measure if and how your creative assets are being used globally.',
    label: typeLabels.CreativeLifecycleReport,
    svg: creativeLifecycleSvg,
  },
};

function SelectType({ types }) {
  useViewPage();

  return (
    <>
      <div className={styles.instructions}>
        <h5>Select the type of report you want to build:</h5>
      </div>
      <div className={styles.cards}>
        { types.map((type) => {
          const {
            description,
            isBeta,
            label,
            svg,
          } = reportTypeData[type] ?? {};

          return (
            <a key={type} href={newReportingReportPath({ type })}>
              <Card className={styles.card} padding={false}>
                { isBeta && (
                  <div className={styles.beta}>
                    <Tag color="purple" label="Beta" />
                  </div>
                ) }
                <img alt="Report Illustration" src={svg} />
                <Divider />
                <div className={styles.text}>
                  <h5>{ label }</h5>
                  <div className="t-caption-1">
                    { description }
                  </div>
                </div>
              </Card>
            </a>
          );
        }) }
      </div>
    </>
  );
}

SelectType.propTypes = propTypes;

export default SelectType;
