import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Icon,
  MaxWidthText,
  Pagination,
  Table,
} from '@makeably/creativex-design-system';
import SearchBar from 'components/molecules/SearchBar';
import Modal from 'components/reusable/modal.coffee';
import { preflightPretestsPath } from 'utilities/routes';
import styles from './SubmittedPreflights.module.css';

const propTypes = {
  displayBrand: PropTypes.bool.isRequired,
  displayQuality: PropTypes.bool.isRequired,
  displayRegulatory: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      brandResult: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      channel: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.array,
      excellentCreatives: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      key: PropTypes.string,
      name: PropTypes.shape({
        creativeName: PropTypes.string,
        creativeThumbnail: PropTypes.string,
        isVideo: PropTypes.bool,
        modal: PropTypes.shape({
          className: PropTypes.string,
          contentLocation: PropTypes.string,
          id: PropTypes.string,
          loadOnOpen: PropTypes.bool,
        }),
        pretestProcessingStatus: PropTypes.string,
      }),
      pdfDownload: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      regulatoryResult: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      rulesMet: PropTypes.string,
      score: PropTypes.string,
      submitted: PropTypes.string,
    }),
  ).isRequired,
  searchValue: PropTypes.string,
};

const defaultProps = {
  searchValue: undefined,
};

function navigateToPage(page, searchValue) {
  const params = { page };
  if (searchValue) params.search_term = searchValue;

  window.location.href = preflightPretestsPath(params);
}

function headers(displayRegulatory, displayBrand, displayQuality) {
  let conditionalHeaders = [];
  if (displayRegulatory) conditionalHeaders.push({ value: 'Compliant' });

  if (displayBrand) conditionalHeaders.push({ value: 'Branded' });

  if (displayQuality) {
    conditionalHeaders.unshift({
      className: styles.highQualityColumn,
      value: 'High Quality Creatives',
    });
    conditionalHeaders = conditionalHeaders.concat([
      { value: 'Score' },
      { value: 'Rules Met' },
    ]);
  }

  return [
    { value: '' },
    { value: 'Name' },
    { value: 'Submitted' },
    { value: 'Channel' },
    ...conditionalHeaders,
    { value: 'PDF' },
  ];
}

function creativeCell({
  children,
  isChild,
  name: {
    creativeName,
    creativeThumbnail,
    isVideo,
    modal,
    pretestProcessingStatus,
  },
}) {
  const hasChildren = children?.length > 0;

  const thumbnailClasses = classNames(
    styles.creativeContainer,
    { [styles.creativeContainerHover]: isChild || !hasChildren },
  );
  const thumbnail = (
    <div className={thumbnailClasses} data-target={!hasChildren && modal?.id}>
      { isVideo && (
        <div className={styles.videoContainer}>
          <Icon color="white" name="play" />
        </div>
      ) }
      <img alt="creative thumbnail" src={creativeThumbnail} />
    </div>
  );

  const thumbnailWithChildren = (
    <div className={styles.badgeContainer}>
      { thumbnail }
      <div className={styles.badge}>{ children?.length }</div>
    </div>
  );

  const containerClasses = classNames(
    styles.details,
    { [styles.margin]: isChild },
  );

  return (
    <div className={containerClasses}>
      { modal && <Modal {...modal} /> }
      { hasChildren ? thumbnailWithChildren : thumbnail }
      <div className={styles.status}>
        <MaxWidthText text={creativeName}>
          { creativeName }
        </MaxWidthText>
        <span>{ pretestProcessingStatus }</span>
      </div>
    </div>
  );
}

function resultCell(result) {
  if (typeof result === 'string') return result;

  const color = result ? 'green' : 'red';
  const icon = result ? 'checkCircle' : 'exclamationCircle';

  return <Icon color={color} name={icon} />;
}

function downloadIconCell(pdfData) {
  if (!pdfData) return '';

  const { name, url } = pdfData;

  return (
    <a download={name} href={url}>
      <Icon display="outline" name="download" />
    </a>
  );
}

function SubmittedPreflights({
  displayBrand,
  displayQuality,
  displayRegulatory,
  pagination: {
    currentPage,
    perPage,
    total,
  },
  searchValue,
  tableData,
}) {
  const tableDataWithIndex = tableData.map((data, index) => ({
    ...data,
    index,
  }));

  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [expandedData, setExpandedData] = useState(tableDataWithIndex);

  useEffect(() => {
    if (expandedIndex >= 0) {
      const expandedItem = tableDataWithIndex[expandedIndex];
      const children = expandedItem.children.map((child) => ({
        ...child,
        isChild: true,
      }));
      const expanded = [
        expandedItem,
        ...children,
      ];
      const before = tableDataWithIndex.slice(0, expandedIndex);
      const after = tableDataWithIndex.slice(expandedIndex + 1);

      setExpandedData([...before, ...expanded, ...after]);
    } else {
      setExpandedData(tableDataWithIndex);
    }
  }, [expandedIndex]);

  const formatRow = (item) => {
    const {
      brandResult,
      channel,
      children,
      excellentCreatives,
      index,
      name: { creativeName },
      pdfDownload,
      regulatoryResult,
      rulesMet,
      score,
      submitted,
    } = item;

    let conditionalCells = [];
    if (displayRegulatory) conditionalCells.push({ value: resultCell(regulatoryResult) });
    if (displayBrand) conditionalCells.push({ value: resultCell(brandResult) });
    if (displayQuality) {
      conditionalCells.unshift({ value: resultCell(excellentCreatives) });
      conditionalCells = conditionalCells.concat([
        { value: score },
        { value: rulesMet },
      ]);
    }

    let expandButton = '';
    if (children?.length > 0) {
      const onClick = () => {
        if (index === expandedIndex) {
          setExpandedIndex(-1);
        } else {
          setExpandedIndex(index);
        }
      };

      expandButton = (
        <Button
          iconLeft={index === expandedIndex ? 'chevronDown' : 'chevronRight'}
          variant="round"
          onClick={onClick}
        />
      );
    }

    return {
      key: `${creativeName}_${index}`,
      cells: [
        { value: expandButton },
        { value: creativeCell(item) },
        {
          value: new Date(submitted).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        { value: channel },
        ...conditionalCells,
        { value: downloadIconCell(pdfDownload) },
      ],
    };
  };

  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.sla}>
          All pre-flights are evaluated in 24 hours or less.
        </div>
        <SearchBar
          formActionLink={preflightPretestsPath()}
          placeholder="Search"
          searchValue={searchValue ?? undefined}
        />
      </div>
      <Divider margin />
      <Table
        className={styles.table}
        headers={headers(displayRegulatory, displayBrand, displayQuality)}
        rows={expandedData.map(formatRow)}
      />
      { total > perPage && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={currentPage}
            perPage={perPage}
            total={total}
            onPageChange={(page) => navigateToPage(page)}
          />
        </div>
      ) }
    </Card>
  );
}

SubmittedPreflights.propTypes = propTypes;
SubmittedPreflights.defaultProps = defaultProps;

export default SubmittedPreflights;
