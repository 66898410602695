import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextArea,
} from '@makeably/creativex-design-system';
import HelperTextInput from 'components/molecules/HelperTextInput';
import styles from './ScoreDetailInputs.module.css';

const propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  qualityRate: PropTypes.string.isRequired,
  qualitySpendRate: PropTypes.string.isRequired,
  scoreDenyList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setIsNextDisabled: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onQualityRateChange: PropTypes.func.isRequired,
  onQualitySpendRateChange: PropTypes.func.isRequired,
  initialCopyValues: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    qualityRate: PropTypes.string,
    qualitySpendRate: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
};

const defaultProps = {
  initialCopyValues: {
    description: undefined,
    label: undefined,
    name: undefined,
    qualityRate: undefined,
    qualitySpendRate: undefined,
  },
  isEditing: false,
};

function getErrors(isError) {
  if (!isError) return [];

  return [{ value: 'The suggested name is already in use. Please rename.' }];
}

function ScoreDetailInputs({
  description,
  initialCopyValues,
  isEditing,
  label,
  name,
  qualityRate,
  qualitySpendRate,
  setIsNextDisabled,
  scoreDenyList,
  onDescriptionChange,
  onLabelChange,
  onNameChange,
  onQualityRateChange,
  onQualitySpendRateChange,
}) {
  const [advancedVisible, setAdvancedVisible] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [qualityRateChanged, setQualityRateChanged] = useState(false);
  const [qualitySpendRateChanged, setQualitySpendRateChanged] = useState(false);

  const validateItem = (item, referenceList) => {
    const itemLowerCase = item.toLowerCase();
    const referenceListLowerCase = referenceList.map((v) => v.toLowerCase());
    const isDenied = scoreDenyList.includes(itemLowerCase);
    const isUsed = itemLowerCase !== '' && referenceListLowerCase.includes(itemLowerCase);
    return isDenied || isUsed;
  };

  const labelError = validateItem(label, [name, qualityRate, qualitySpendRate]);
  const nameError = validateItem(name, [label, qualityRate, qualitySpendRate]);
  const qualityRateError = validateItem(qualityRate, [label, name, qualitySpendRate]);
  const qualitySpendRateError = validateItem(qualitySpendRate, [label, name, qualityRate]);

  const inputEmpty = [label, name, qualityRate, qualitySpendRate].some((v) => v === '');
  const inputNotChanged = (
    initialCopyValues.label === label
      && initialCopyValues.description === description
      && initialCopyValues.name === name
      && initialCopyValues.qualityRate === qualityRate
      && initialCopyValues.qualitySpendRate === qualitySpendRate
  );

  const nextDisabled = (
    labelError
      || nameError
      || qualityRateError
      || qualitySpendRateError
      || inputEmpty
      || inputNotChanged
  );

  useEffect(() => {
    setIsNextDisabled(nextDisabled);
  }, [label, description, name, qualityRate, qualitySpendRate]);

  const onScoreLabelChange = (newLabel) => {
    onLabelChange(newLabel);

    if (isEditing) return;

    if (!nameChanged) onNameChange(`${newLabel} Score`);
    if (!qualityRateChanged) onQualityRateChange(`${newLabel}: Quality Rate`);
    if (!qualitySpendRateChanged) onQualitySpendRateChange(`${newLabel}: Quality Spend Rate`);
  };

  const onScoreNameChange = (newName) => {
    onNameChange(newName);
    setNameChanged(true);
  };

  const onRateChange = (newRate) => {
    onQualityRateChange(newRate);
    setQualityRateChanged(true);
  };

  const onSpendRateChange = (newSpendRate) => {
    onQualitySpendRateChange(newSpendRate);
    setQualitySpendRateChanged(true);
  };

  return (
    <div className={styles.container}>
      <HelperTextInput
        helperText={getErrors(labelError)}
        isError={labelError}
        label="Label"
        placeholder=""
        size="large"
        value={label}
        onChange={onScoreLabelChange}
      />
      <TextArea
        label="Description"
        placeholder="Optional"
        size="large"
        value={description}
        onChange={(newDescription) => onDescriptionChange(newDescription)}
      />
      <div className={styles.settingsHeader}>
        <Button
          iconLeft="settingsGear"
          iconRight={advancedVisible ? 'chevronDown' : 'chevronUp'}
          label="Advanced Metric Settings"
          variant="tertiary"
          onClick={() => setAdvancedVisible((prevState) => !prevState)}
        />
      </div>
      { advancedVisible && (
        <div className={styles.settingsBody}>
          <HelperTextInput
            helperText={getErrors(nameError)}
            isError={nameError}
            label="Custom Score Name"
            size="large"
            value={name}
            onChange={onScoreNameChange}
          />
          <HelperTextInput
            helperText={getErrors(qualityRateError)}
            isError={qualityRateError}
            label="Custom Quality Rate"
            size="large"
            value={qualityRate}
            onChange={onRateChange}
          />
          <HelperTextInput
            helperText={getErrors(qualitySpendRateError)}
            isError={qualitySpendRateError}
            label="Custom Quality Spend Rate"
            size="large"
            value={qualitySpendRate}
            onChange={onSpendRateChange}
          />
        </div>
      ) }
    </div>
  );
}

ScoreDetailInputs.propTypes = propTypes;
ScoreDetailInputs.defaultProps = defaultProps;

export default ScoreDetailInputs;
