import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@makeably/creativex-design-system';
import { isNumeric } from 'utilities/string';

export const guidelineProps = {
  channelId: PropTypes.number,
  channelKey: PropTypes.string,
  channelLabel: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  name: PropTypes.string,
};

const propTypes = {
  getStepContent: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultWeight: PropTypes.number,
  description: PropTypes.string,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape(guidelineProps),
  ),
  initialStep: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  qualityRate: PropTypes.string,
  qualitySpendRate: PropTypes.string,
  scoreDenyList: PropTypes.arrayOf(PropTypes.string),
  scoreId: PropTypes.number,
};

const defaultProps = {
  defaultWeight: 0,
  description: '',
  guidelines: [],
  initialStep: 1,
  label: '',
  name: '',
  qualityRate: '',
  qualitySpendRate: '',
  scoreDenyList: [],
  scoreId: undefined,
};

function formatInitialGuidelines(guidelines, defaultWeight) {
  const guidelineSelected = guidelines.reduce((all, guideline) => {
    const isSelected = all[guideline.id] || guideline.isSelected || false;

    return {
      ...all,
      [guideline.id]: isSelected,
    };
  }, {});

  return guidelines.map((guideline) => {
    const weight = guideline.weight ?? defaultWeight;

    return {
      ...guideline,
      initialSelected: guideline.isSelected,
      initialWeight: weight,
      isSelected: guidelineSelected[guideline.id],
      weight,
    };
  });
}

function ScoreModal({
  defaultWeight,
  description: initialDescription,
  getStepContent,
  guidelines: initialGuidelines,
  initialStep,
  isOpen,
  label: initialLabel,
  name: initialName,
  qualityRate: initialQualityRate,
  qualitySpendRate: initialQualitySpendRate,
  scoreDenyList,
  scoreId,
  onClose: onModalClose,
}) {
  const [step, setStep] = useState(initialStep);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const [description, setDescription] = useState(initialDescription);
  const [label, setLabel] = useState(initialLabel);
  const [name, setName] = useState(initialName);
  const [qualityRate, setQualityRate] = useState(initialQualityRate);
  const [qualitySpendRate, setQualitySpendRate] = useState(initialQualitySpendRate);

  const [guidelines, setGuidelines] = useState(
    formatInitialGuidelines(initialGuidelines, defaultWeight),
  );

  const initialSelectedGuidelines = guidelines.filter(({ initialSelected }) => initialSelected);
  const selectedGuidelines = guidelines.filter(({ isSelected }) => isSelected);
  const numSelectedGuidelines = [...new Set(selectedGuidelines.map(({ id }) => id))].length;

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const onNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const onPrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const onClose = () => {
    setDescription(initialDescription);
    setGuidelines(formatInitialGuidelines(initialGuidelines, defaultWeight));
    setLabel(initialLabel);
    setIsNextDisabled(false);
    setName(initialName);
    setQualityRate(initialQualityRate);
    setQualitySpendRate(initialQualitySpendRate);
    setStep(initialStep);

    onModalClose();
  };

  const onGuidelineSelect = (isChecked, guidelineId) => {
    const newGuidelines = guidelines.map((guideline) => {
      if (guideline.id === guidelineId) {
        return {
          ...guideline,
          isSelected: isChecked,
        };
      }

      return guideline;
    });

    setGuidelines(newGuidelines);
  };

  const onGuidelineWeightChange = (key, guidelineId, weight = defaultWeight) => {
    setGuidelines((prevGuidelines) => {
      // Even with type=number inputs will allow certain letters as input (ex: 'e')
      if (!isNumeric(weight)) return prevGuidelines;

      const idx = prevGuidelines
        .findIndex(({ channelKey, id }) => id === guidelineId && channelKey === key);

      const newWeight = weight === '' ? defaultWeight : parseInt(weight);
      const newGuideline = {
        ...prevGuidelines[idx],
        weight: newWeight,
      };

      return [
        ...prevGuidelines.slice(0, idx),
        newGuideline,
        ...prevGuidelines.slice(idx + 1),
      ];
    });
  };

  const stepHelperProps = {
    description,
    guidelines,
    initialDescription,
    initialGuidelines,
    initialLabel,
    initialName,
    initialQualityRate,
    initialQualitySpendRate,
    initialSelectedGuidelines,
    initialStep,
    isNextDisabled,
    label,
    name,
    numSelectedGuidelines,
    onClose,
    onGuidelineSelect,
    onGuidelineWeightChange,
    onNext,
    onPrevious,
    qualityRate,
    qualitySpendRate,
    scoreDenyList,
    scoreId,
    selectedGuidelines,
    setDescription,
    setIsNextDisabled,
    setLabel,
    setName,
    setQualityRate,
    setQualitySpendRate,
  };
  const {
    content,
    footer,
    header,
    size,
  } = getStepContent(step, stepHelperProps);

  return (
    <BasicModal
      footer={footer}
      header={header}
      isOpen={isOpen}
      size={size}
      onClose={onClose}
    >
      { content }
    </BasicModal>
  );
}

ScoreModal.propTypes = propTypes;
ScoreModal.defaultProps = defaultProps;

export default ScoreModal;
