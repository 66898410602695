import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import Slider from 'components/atoms/Slider';
import { addToast } from 'components/organisms/Toasts';
import { secondsToMinutes } from 'utilities/date';
import { logError } from 'utilities/logging';
import styles from './VideoControls.module.css';

const MS_IN_S = 1000;
// note: This corresponds to the media readyState property. See more here:
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
const VIDEO_HAS_METADATA = 1;
const VOLUME_BAR_WIDTH = 80;
const VOLUME_MAX = 1;
const VOLUME_MIN = 0;

const propTypes = {
  videoRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  // note: value in milliseconds
  progressStep: PropTypes.number,
  volumeStep: PropTypes.number,
};

const defaultProps = {
  progressStep: 500,
  volumeStep: 0.1,
};

function VideoControls({
  progressStep, videoRef, volumeStep,
}) {
  const [videoTime, setVideoTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isProgressChanging, setIsProgressChanging] = useState(false);
  const [isVolumeChanging, setIsVolumeChanging] = useState(false);
  const [isVolumeHover, setIsVolumeHover] = useState(false);
  const [prevIsPlaying, setPrevIsPlaying] = useState(false);
  const [prevVideoVolume, setPrevVideoVolume] = useState(VOLUME_MAX);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoVolume, setVideoVolume] = useState(VOLUME_MAX);

  useEffect(() => {
    const player = videoRef.current;

    if (isPlaying) {
      player.play();
    } else {
      player.pause();
    }
  }, [isPlaying]);

  // note: pause video while progress slider is being manipulated
  useEffect(() => {
    if (isProgressChanging) {
      setPrevIsPlaying(isPlaying);
      setIsPlaying(false);
    } else {
      setIsPlaying(prevIsPlaying);
    }
  }, [isProgressChanging]);

  useEffect(() => {
    const player = videoRef.current;

    player.volume = videoVolume;
  }, [videoVolume]);

  const onPlayClick = () => {
    setIsPlaying((prevPlaying) => !prevPlaying);
  };

  const onVolumeMute = () => {
    if (videoVolume === VOLUME_MIN) {
      setVideoVolume(prevVideoVolume);
    } else {
      setPrevVideoVolume(videoVolume);
      setVideoVolume(VOLUME_MIN);
    }
  };

  const onVideoEnd = () => {
    setIsPlaying(false);
  };

  const onVideoError = (e) => {
    addToast('Error loading video.', { type: 'error' });
    logError('Video load error', {
      src: e.target.src,
    });
  };

  const onCurrentTimeUpdate = () => {
    setVideoTime(videoRef.current.currentTime);
  };

  const onPlayerClick = () => {
    setIsPlaying((prevPlaying) => !prevPlaying);
  };

  const onDurationLoaded = () => {
    setVideoDuration(videoRef.current.duration);
  };

  useEffect(() => {
    const player = videoRef.current;

    player.addEventListener('ended', onVideoEnd);
    player.addEventListener('error', onVideoError);
    player.addEventListener('timeupdate', onCurrentTimeUpdate);
    player.addEventListener('click', onPlayerClick);

    return () => {
      player.removeEventListener('ended', onVideoEnd);
      player.removeEventListener('error', onVideoError);
      player.removeEventListener('timeupdate', onCurrentTimeUpdate);
      player.removeEventListener('click', onPlayerClick);
    };
  }, []);

  useEffect(() => {
    const player = videoRef.current;

    // note: If metadata is already loaded, loadedmetadata
    // event won't fire, so we set duration here
    if (player.readyState < VIDEO_HAS_METADATA) {
      player.addEventListener('loadedmetadata', onDurationLoaded);
      return () => player.removeEventListener('loadedmetadata', onDurationLoaded);
    }

    setVideoDuration(player.duration);
    return undefined;
  }, []);

  const handleProgressNav = (e) => {
    const player = videoRef.current;

    if (e.key === 'ArrowLeft') {
      player.currentTime -= (progressStep / MS_IN_S);
      e.preventDefault();
    } else if (e.key === 'ArrowRight') {
      player.currentTime += (progressStep / MS_IN_S);
      e.preventDefault();
    }
  };

  const handleVolumeNav = (e) => {
    if (e.key === 'ArrowLeft') {
      setVideoVolume((prevVolume) => Math.max(prevVolume - volumeStep, VOLUME_MIN));
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === 'ArrowRight') {
      setVideoVolume((prevVolume) => Math.min(prevVolume + volumeStep, VOLUME_MAX));
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleProgressChange = (percent) => {
    const updatedTime = videoDuration * percent;
    const player = videoRef.current;

    player.currentTime = updatedTime;
    setVideoTime(updatedTime);
  };

  const volumeBarStyle = {
    width: isVolumeHover || isVolumeChanging ? VOLUME_BAR_WIDTH : 0,
  };

  return (
    <div
      className={styles.container}
      role="button"
      tabIndex={0}
      onKeyDown={handleProgressNav}
    >
      <div className={styles.progressSlider}>
        <Slider
          percent={videoTime / videoDuration}
          onChange={handleProgressChange}
          onMouseClicked={setIsProgressChanging}
        />
      </div>
      <div className={styles.controls}>
        <button className={styles.play} type="button" onClick={onPlayClick}>
          <Icon color="current" name={isPlaying ? 'pause' : 'play'} />
        </button>
        <div
          className={styles.volumeContainer}
          role="button"
          tabIndex={0}
          onKeyDown={handleVolumeNav}
          onMouseEnter={() => setIsVolumeHover(true)}
          onMouseLeave={() => setIsVolumeHover(false)}
        >
          <button className={styles.volume} type="button" onClick={onVolumeMute}>
            <Icon color="current" name={videoVolume === VOLUME_MIN ? 'soundOff' : 'soundOn'} />
          </button>
          <div className={styles.volumeBar} style={volumeBarStyle}>
            <Slider
              color="grey"
              percent={videoVolume}
              onChange={setVideoVolume}
              onMouseClicked={setIsVolumeChanging}
            />
          </div>
        </div>
        <div className={styles.text}>
          { `${secondsToMinutes(videoTime)} / ${secondsToMinutes(videoDuration)}` }
        </div>
      </div>
    </div>
  );
}

VideoControls.propTypes = propTypes;
VideoControls.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <VideoControls videoRef={ref} {...props} />);
