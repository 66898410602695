import React, { useState } from 'react';
import classNames from 'classnames';
import {
  AddNewButton,
  Button,
  Checkbox,
  CloseButton,
  CopyButton,
  Icon,
  Logo,
  Toggle,
} from '@makeably/creativex-design-system';
import styles from 'components/DesignSystem.module.css';
import { dependencies } from '../../../package.json';

function render(name, component) {
  return (
    <div className={styles.item}>
      <h5 className={styles.itemName}>{ name }</h5>
      { component }
    </div>
  );
}

function renderButtonSection() {
  return (
    <div className={styles.gallery}>
      <div className={styles.galleryTitle}>Buttons</div>
      <div className={styles.galleryItems}>
        { render('Add New', <AddNewButton label="Add User" />) }
        { render('Primary', <Button iconRight="arrowRight" label="Click Me" variant="primary" />) }
        { render('Secondary', <Button iconLeft="filter" label="Filter" variant="secondary" />) }
        { render('Tertiary', <Button label="Button" variant="tertiary" />) }
        { render('Destructive', <Button label="Delete" variant="destructive" />) }
        { render('Close', <CloseButton />) }
        { render('Copy', <CopyButton />) }
      </div>
    </div>
  );
}

function renderFormElementSection(toggleChecked, setToggleChecked) {
  const [checkboxChecked, setCheckboxChecked] = useState(true);

  return (
    <div className={styles.gallery}>
      <div className={styles.galleryTitle}>Form Elements</div>
      <div className={styles.galleryItems}>
        { render('Toggle', <Toggle checked={toggleChecked} label="Switch Me" onChange={(e) => setToggleChecked(e.target.checked)} />) }
        { render('Checkbox', <Checkbox checked={checkboxChecked} label="Item" onChange={(e) => setCheckboxChecked(e.target.checked)} />) }
      </div>
    </div>
  );
}

function renderMiscellaneousSection() {
  return (
    <div className={styles.gallery}>
      <div className={styles.galleryTitle}>Miscellaneous</div>
      <div className={styles.galleryItems}>
        { render('Icon', <Icon color="red" name="xCircle" />) }
        {
            render('Logo',
              <div style={{
                width: '32px',
                height: '32px',
              }}
              >
                <Logo name="instagram" />
              </div>)
        }
      </div>
    </div>
  );
}

function DesignSystem() {
  const version = dependencies['@makeably/creativex-design-system'];
  const [toggleChecked, setToggleChecked] = useState(true);
  // Styles w/ easter egg to toggle item background
  const mainStyles = classNames(styles.main, { [styles.mainAlternate]: !toggleChecked });

  return (
    <>
      <div className={styles.header}>
        <a className={styles.link} href="/admin">
          <Icon name="arrowLeft" />
          Back to Admin
        </a>
      </div>
      <div className={mainStyles}>
        <div className="u-flexColumn">
          <div className={styles.title}>
            <span>Design System Package Test</span>
            <span className={styles.version}>{ `v${version}` }</span>
          </div>
          { renderButtonSection() }
          { renderFormElementSection(toggleChecked, setToggleChecked) }
          { renderMiscellaneousSection() }
        </div>
      </div>
    </>
  );
}

export default DesignSystem;
