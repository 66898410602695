import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@makeably/creativex-design-system';
import Configuration from 'components/scores/show/Configuration';
import History from 'components/scores/show/History';
import Overview from 'components/scores/show/Overview';
import StandardTiers from 'components/scores/show/StandardTiers';

const propTypes = {
  id: PropTypes.number.isRequired,
  isStandard: PropTypes.bool.isRequired,
};

function tabs(setTab, isStandard) {
  const tabOptions = [
    {
      label: 'Overview',
      onClick: () => setTab('Overview'),
    },
    {
      label: 'Configuration',
      onClick: () => setTab('Configuration'),
    },
  ];

  if (isStandard) {
    tabOptions.push({
      label: 'Tiers',
      onClick: () => setTab('Tiers'),
    });
  } else {
    tabOptions.push({
      label: 'History',
      onClick: () => setTab('History'),
    });
  }

  return tabOptions;
}

function Show({ id, isStandard }) {
  const [tab, setTab] = useState('Overview');

  return (
    <>
      <Tabs
        currentTab={tab}
        tabs={tabs(setTab, isStandard)}
        variant="button"
      />
      { tab === 'Overview' && (
        <Overview scoreId={id} />
      ) }
      { tab === 'Configuration' && (
        <Configuration scoreId={id} />
      ) }
      { tab === 'Tiers' && (
        <StandardTiers scoreId={id} />
      ) }
      { tab === 'History' && (
        <History scoreId={id} />
      ) }
    </>
  );
}

Show.propTypes = propTypes;

export default Show;
