import { toggleObject } from '@makeably/creativex-design-system';
import { getItemMultipleFilterTest } from 'utilities/filtering';
import { filterValues } from 'utilities/object';
import { byObjectLabel } from 'utilities/sort';

export function filterItems(items, selections) {
  const filterTest = getItemMultipleFilterTest(selections);
  return items.filter(filterTest);
}

export function getKeyOptions(key, items) {
  const all = new Map();

  items.map((item) => {
    const dimension = item[key] ?? {};
    const {
      label, options, value,
    } = dimension;

    if (options) {
      options?.map((option) => all.set(option.value, option));
    } else {
      all.set(value, {
        label: label ?? value,
        value,
      });
    }

    return true;
  });

  return [...all.values()].sort(byObjectLabel);
}

export function getOptions(dimensions, items) {
  return dimensions.reduce((all, { key }) => ({
    ...all,
    [key]: getKeyOptions(key, items),
  }), {});
}

export function updateSelections(selections, key, selected) {
  const last = selections[key] ?? [];

  const updated = {
    ...selections,
    [key]: toggleObject(last, selected),
  };

  return filterValues(updated, (arr) => arr?.length > 0);
}
