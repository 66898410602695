import React, { useEffect } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import {
  Toast,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import { useTimeout } from 'utilities/hooks';
import styles from './Toasts.module.css';

const storageName = 'cxToasts';
const addEventName = 'toast:add';
const defaultOptions = {
  duration: 5000,
  hasTimeout: true,
  size: 'medium',
  type: 'success',
};

export function addToast(message, userOptions = {}) {
  const options = {
    ...defaultOptions,
    ...userOptions,
  };
  const {
    duration, hasTimeout, ...other
  } = options;
  const removeAt = hasTimeout ? Date.now() + duration : null;
  const uuid = uuidv4();

  const detail = {
    ...other,
    message,
    removeAt,
    uuid,
  };
  document.dispatchEvent(new CustomEvent(addEventName, { detail }));
}

function getNextTimeout(toasts) {
  const now = Date.now();

  return toasts.reduce((timeoutMin, { removeAt }) => {
    if (!removeAt) return timeoutMin;

    const timeout = removeAt - now;
    if (!timeoutMin || timeout < timeoutMin) {
      return timeout;
    }

    return timeoutMin;
  }, null);
}

function updateToastsByState(toasts) {
  // Remove 'hiding' toasts, update 'showing' to 'shown'
  return toasts.reduce((all, toast) => {
    if (toast.state === 'hiding') {
      return all;
    } else if (toast.state === 'showing') {
      return [...all, {
        ...toast,
        state: 'shown',
      }];
    }
    return [...all, toast];
  }, []);
}

function hideTimedOutToasts(toasts) {
  const now = Date.now();

  return toasts.map((toast) => {
    const isTimedOut = toast.removeAt ? now >= toast.removeAt : false;

    if (isTimedOut) {
      return {
        ...toast,
        state: 'hiding',
        removeAt: null,
      };
    }
    return toast;
  });
}

function hideToast(toasts, uuid) {
  const index = toasts.findIndex((t) => t.uuid === uuid);
  const toast = toasts[index];
  const before = toasts.slice(0, index);
  const after = toasts.slice(index + 1);

  return [...before, {
    ...toast,
    state: 'hiding',
  }, ...after];
}

function Toasts() {
  const [toasts, setToasts] = useLocalStorage(storageName, []);
  const nextTimeout = getNextTimeout(toasts);

  const updateToasts = () => {
    setToasts((lastToasts) => updateToastsByState(lastToasts));
  };

  useEffect(() => {
    const receiveToast = ({ detail }) => {
      setToasts((lastToasts) => [...lastToasts, {
        ...detail,
        state: 'showing',
      }]);
      setTimeout(updateToasts, 0);
    };

    updateToasts();
    document.addEventListener(addEventName, receiveToast);

    return () => {
      document.removeEventListener(addEventName, receiveToast);
    };
  }, []);

  useTimeout(() => {
    setToasts((lastToasts) => hideTimedOutToasts(lastToasts));
  }, nextTimeout);

  const handleHide = (uuid) => {
    setToasts((lastToasts) => hideToast(lastToasts, uuid));
  };

  const renderToast = ({
    message,
    size,
    state,
    type,
    uuid,
  }) => {
    const classes = classNames(
      styles.toast,
      {
        [styles.shown]: state === 'shown',
      },
    );

    return (
      <div
        key={uuid}
        className={classes}
        onTransitionEnd={updateToasts}
      >
        <Toast
          message={message}
          size={size}
          type={type}
          onClose={() => handleHide(uuid)}
        />
      </div>
    );
  };

  return (
    <div className={styles.toasts}>
      { toasts.map((toast) => renderToast(toast)) }
    </div>
  );
}

export default Toasts;
