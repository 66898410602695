// Utilities for organizing assets into categorized bins
// and creating items from those bins for display
// Some assets will have been matched with inflight assets, which associate them with speific
// channels and markets. They are called localized assetss, and we split them into all of the
// channel and market combinations
// An unlocalized asset is one which does not have any matching inflights and therefore
// has no channel and market information
//
// The assets are first split into bins by the selected asset keys,
// which are all of the dimension keys except channel and market
// Then, these bins are further split into localized bins by channel and market
// The localized bins keep the asset count of the asset bin from which they are split
// Unlocalized bins are then created for assets that don't match any channel or market
//
// This code uses the following data definitions:
//   option: a dropdown option object (i.e. { value: 'assetType', label: 'Asset Type' })
//   key: a dimension by which to split assets (i.e. 'assetType')
//   asset: an object with object values for assetType, brand, etc and localizedCounts
//   localizedCounts: an object with '::Channel::Market' keys and number values
//   tag: a unique bin key (i.e. 'Video::Canada::DV360')
//   bin: an object with tag, assetCount, and assets
//   binsByTag: an object with tag keys and bin values
import React from 'react';
import UuidDisplay from 'components/molecules/UuidDisplay';
import {
  EMPTY_VALUE,
  NONE_KEY,
} from 'components/reporting/utilities';
import {
  appendIfUnique,
  getUniqueValues,
} from 'utilities/array';
import { creativeLifecycleCoreAssetPath } from 'utilities/routes';
import {
  titleize,
  toPercent,
} from 'utilities/string';

const allLocalizedKeys = ['channel', 'market'];

function splitKeys(options, localized) {
  const keys = options.map((option) => option.value);

  return keys.reduce(([assetKeys, localizedKeys], key) => {
    if (localized.includes(key)) {
      return [assetKeys, [...localizedKeys, key]];
    }
    return [[...assetKeys, key], localizedKeys];
  }, [[], []]);
}

export function getAssetLabel(asset, key) {
  switch (key) {
    case 'assetType':
      return titleize(asset[key]);
    case 'channel':
    case 'market':
      return (asset[key] === EMPTY_VALUE) ? 'N/A' : null;
    case 'campaign':
      return asset[key];
    case 'uuid':
      return asset[key];
    case 'fileName':
      return `${asset[key].slice(0, 6)}...`;
    default:
      return null;
  }
}

function getAssetCount(assets) {
  const uniqueCoreAssets = new Set(
    assets.map(({ uuid }) => uuid).filter((uuid) => !!uuid),
  );

  return uniqueCoreAssets.size;
}

function getBinTag(asset, keys) {
  const values = keys.map((key) => asset[key]);

  return values.join('::');
}

function splitLocalizedKey(matchKey) {
  const [channel, market, assetIdStr, scoreStr, postIdStr] = matchKey.split('::');
  const assetId = parseInt(assetIdStr);
  const score = parseFloat(scoreStr);
  const postId = parseInt(postIdStr);

  return {
    assetId: Number.isNaN(assetId) ? null : assetId,
    channel,
    market,
    postId: Number.isNaN(postId) ? null : postId,
    score: Number.isNaN(score) ? null : score,
  };
}

function getLocalizedBinTag(tag, valuesByKey, localizedKeys) {
  if (localizedKeys.length === 0) return tag;

  const localizedValues = localizedKeys.map((key) => valuesByKey[key]);

  return `${tag}::${localizedValues.join('::')}`;
}

function getLocalizedAssets(asset, binTag, localizedKeys) {
  const countsByTag = asset.posts.reduce((all, key) => {
    const {
      assetId,
      channel,
      market,
      score,
      postId,
    } = splitLocalizedKey(key);

    const localizedTag = getLocalizedBinTag(binTag, {
      channel,
      market,
    }, localizedKeys);
    const existing = all[localizedTag] ?? {
      ...asset,
      assetIds: [],
      channel,
      localizedTag,
      market,
      scoresWithPostIds: new Map(),
    };

    const currentScorePostIds = existing.scoresWithPostIds.get(score) ?? [];
    const newScorePostIds = appendIfUnique(currentScorePostIds, postId);

    return {
      ...all,
      [localizedTag]: {
        ...existing,
        assetIds: appendIfUnique(existing.assetIds, assetId),
        scoresWithPostIds: existing.scoresWithPostIds.set(score, newScorePostIds),
      },
    };
  }, {});

  return Object.values(countsByTag);
}

function getLocalizedBins(assetBin, localizedKeys) {
  const { assets, tag } = assetBin;
  const assetCount = getAssetCount(assets);

  // Split each asset into an array of localized assets
  const localizedAssets = assets.reduce((all, asset) => ([
    ...all,
    ...getLocalizedAssets(asset, tag, localizedKeys),
  ]), []);

  // Sort localized assets into bins
  const defaultBin = { assets: [] };
  return localizedAssets.reduce((bins, asset) => {
    const { localizedTag } = asset;
    const bin = bins[localizedTag] ?? defaultBin;

    return {
      ...bins,
      [localizedTag]: {
        ...bin,
        assetCount,
        assets: [...bin.assets, asset],
        tag: localizedTag,
      },
    };
  }, {});
}

function getUnlocalizedBins(assetBins, localizedBins) {
  const assetTags = Object.keys(assetBins);
  const localizedTags = Object.keys(localizedBins);

  return assetTags.reduce((all, assetTag) => {
    const isUnlocalized = !localizedTags.some((tag) => tag.startsWith(assetTag));
    const bin = assetBins[assetTag];
    const assets = bin.assets.map((asset) => ({
      ...asset,
      channel: EMPTY_VALUE,
      assetIds: [],
      market: EMPTY_VALUE,
      scoresWithPostIds: new Map(),
    }));

    if (isUnlocalized) {
      return {
        ...all,
        [assetTag]: {
          assetCount: getAssetCount(assets),
          assets,
          tag: assetTag,
        },
      };
    }

    return all;
  }, {});
}

export function getBinsByTag(options, assets) {
  const [assetKeys, localizedKeys] = splitKeys(options, allLocalizedKeys);
  const defaultBin = { assets: [] };

  // Sort the assets by unique assetKey combinations
  const binsByTag = assets.reduce((bins, asset) => {
    const tag = getBinTag(asset, assetKeys);
    const bin = bins[tag] ?? defaultBin;

    return {
      ...bins,
      [tag]: {
        ...bin,
        tag,
        assets: [...bin.assets, asset],
      },
    };
  }, {});

  // Split and sort the assets into localized bins
  const assetBins = Object.values(binsByTag);
  const localizedBins = assetBins.reduce((bins, assetBin) => ({
    ...bins,
    ...getLocalizedBins(assetBin, localizedKeys),
  }), {});

  // Find any remaining bins that weren't localized (no matching inflights)
  const unlocalizedBins = getUnlocalizedBins(binsByTag, localizedBins);

  return {
    ...localizedBins,
    ...unlocalizedBins,
  };
}

function getDimension(asset, key) {
  let dimension = {
    label: getAssetLabel(asset, key),
    value: asset[key],
  };

  if (key === 'uuid' || key === 'fileName') {
    const url = creativeLifecycleCoreAssetPath(asset.uuid);

    dimension = {
      ...dimension,
      element: <UuidDisplay url={url} uuid={asset[key]} />,
    };
  }

  return dimension;
}

function getDimensionsObject(bin, selectedDimensions) {
  // All assets are the same, so take the first one
  const { assets } = bin;
  const asset = assets[0];
  const keys = selectedDimensions.map((dim) => dim.value);

  if (keys.length === 0) {
    return {
      [NONE_KEY]: { value: 'Total' },
    };
  }

  return keys.reduce((obj, key) => ({
    ...obj,
    [key]: getDimension(asset, key),
  }), {});
}

function sumScores(scoresWithPostIds, processedPostIds) {
  return [...scoresWithPostIds.keys()].reduce((sum, score) => {
    const uniquePosts = getUniqueValues(scoresWithPostIds.get(score), [...processedPostIds]);
    return sum + (score * uniquePosts.length);
  }, 0);
}

function getMetricTotals(assets) {
  const {
    activatedAssets,
    activatedIds,
    postIds,
    scoreSum,
  } = assets.reduce((totals, asset) => {
    const postIdsFlattened = [...asset.scoresWithPostIds.values()].flat();
    const newScoreSum = sumScores(asset.scoresWithPostIds, totals.postIds);

    return {
      activatedAssets:
        asset.posts.length > 0 ? totals.activatedAssets.add(asset.uuid) : totals.activatedAssets,
      activatedIds: new Set([...totals.activatedIds, ...asset.assetIds]),
      postIds: new Set([...totals.postIds, ...postIdsFlattened]),
      scoreSum: totals.scoreSum + newScoreSum,
    };
  }, {
    activatedAssets: new Set(),
    activatedIds: new Set(),
    postIds: new Set(),
    scoreSum: 0,
  });

  return {
    activated: activatedAssets.size,
    activations: activatedIds.size,
    localizations: postIds.size,
    scoreSum,
  };
}

function getMetricsObject(bin) {
  const { assetCount, assets } = bin;
  const {
    activated,
    activations,
    localizations,
    scoreSum,
  } = getMetricTotals(assets);

  const activationRate = activated / assetCount;
  const repurposedRate = activated > 0 ? activations / activated : 0;
  const reusageRate = activated > 0 ? localizations / activated : 0;
  const score = localizations > 0 ? scoreSum / localizations : null;

  return {
    assets: { value: assetCount },
    assetsActivated: { value: activated },
    assetsNotActivated: { value: assetCount - activated },
    activationRate: {
      value: Math.round(activationRate * 1000) / 1000,
      label: toPercent(activationRate, 1),
    },
    averageScore: {
      value: score ?? 0,
      label: toPercent(score) ?? 'N/A',
    },
    repurposedRate: {
      value: repurposedRate,
      label: `${repurposedRate.toFixed(1)}x`,
    },
    reusageRate: {
      value: reusageRate,
      label: `${reusageRate.toFixed(1)}x`,
    },
  };
}

export function getItems(bins, selectedDimensions) {
  return Object.values(bins).map((bin, index) => ({
    index: { value: index + 1 },
    id: { value: bin.tag },
    ...getDimensionsObject(bin, selectedDimensions),
    ...getMetricsObject(bin),
  }));
}
