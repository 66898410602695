import React from 'react';
import PropTypes from 'prop-types';
import { coreAssetProps } from 'components/reporting/CoreAssetsFilter';
import CreativeLifecycleReport from 'components/reporting/CreativeLifecycleReport';
import ScoreReport from 'components/reporting/ScoreReport';
import {
  customFilterProps,
  dateOptionProps,
  propertiesProps,
  scoreProps,
  guidelineScoreProps,
  guidelinesDetailsProps,
} from 'components/reporting/shared';
import TimePeriodReport from 'components/reporting/TimePeriodReport';
import { useViewPage } from 'utilities/mixpanel';
import GuidelineReport from './GuidelineReport';

const propTypes = {
  initialDescription: PropTypes.string.isRequired,
  initialProperties: propertiesProps.isRequired,
  initialTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  canViewSpend: PropTypes.bool,
  coreAssets: PropTypes.arrayOf(coreAssetProps),
  customFilters: PropTypes.arrayOf(customFilterProps),
  dateOptions: PropTypes.arrayOf(dateOptionProps),
  guidelineScores: PropTypes.arrayOf(guidelineScoreProps),
  guidelinesDetails: PropTypes.arrayOf(guidelinesDetailsProps),
  scores: PropTypes.arrayOf(scoreProps),
};

const defaultProps = {
  canViewSpend: false,
  coreAssets: [],
  customFilters: [],
  dateOptions: [],
  guidelineScores: [],
  guidelinesDetails: [],
  scores: [],
};

function EditReport({
  canViewSpend,
  coreAssets,
  customFilters,
  dateOptions,
  initialDescription,
  initialProperties,
  initialTitle,
  scores,
  type,
  uuid,
  guidelineScores,
  guidelinesDetails,
}) {
  useViewPage('', {
    uuid,
    type,
  });

  switch (type) {
    case 'ScoreReport':
      return (
        <ScoreReport
          canViewSpend={canViewSpend}
          customFilters={customFilters}
          dateOptions={dateOptions}
          initialDescription={initialDescription}
          initialProperties={initialProperties}
          initialTitle={initialTitle}
          scores={scores}
          type={type}
          uuid={uuid}
        />
      );
    case 'TimePeriodReport':
      return (
        <TimePeriodReport
          canViewSpend={canViewSpend}
          customFilters={customFilters}
          dateOptions={dateOptions}
          initialDescription={initialDescription}
          initialProperties={initialProperties}
          initialTitle={initialTitle}
          scores={scores}
          type={type}
          uuid={uuid}
        />
      );
    case 'GuidelineReport':
      return (
        <GuidelineReport
          canViewSpend={canViewSpend}
          customFilters={customFilters}
          dateOptions={dateOptions}
          guidelineScores={guidelineScores}
          guidelinesDetails={guidelinesDetails}
          initialDescription={initialDescription}
          initialProperties={initialProperties}
          initialTitle={initialTitle}
          scores={scores}
          type={type}
          uuid={uuid}
        />
      );
    case 'CreativeLifecycleReport':
      return (
        <CreativeLifecycleReport
          coreAssets={coreAssets}
          initialDescription={initialDescription}
          initialProperties={initialProperties}
          initialTitle={initialTitle}
          type={type}
          uuid={uuid}
        />
      );
    default:
      return <div>{ `Unknown Report Type: ${type}` }</div>;
  }
}

EditReport.propTypes = propTypes;
EditReport.defaultProps = defaultProps;

export default EditReport;
