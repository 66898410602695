import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@makeably/creativex-design-system';
import styles from './VideoWrapper.module.css';

const propTypes = {
  source: PropTypes.string.isRequired,
  videoRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};

function VideoWrapper({ source, videoRef }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={styles.video}>
      <video
        ref={videoRef}
        className={styles.player}
        src={source}
        onCanPlayThrough={() => setIsLoading(false)}
      />
      { isLoading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) }
    </div>
  );
}

VideoWrapper.propTypes = propTypes;

export default React.forwardRef((props, ref) => <VideoWrapper videoRef={ref} {...props} />);
