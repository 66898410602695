import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import VideoControls from 'components/molecules/VideoControls';
import VideoWrapper from 'components/molecules/VideoWrapper';

const propTypes = {
  source: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

const defaultProps = {
  height: '100%',
  width: '100%',
};

function VideoPlayer({
  height, source, width,
}) {
  const videoRef = useRef(null);

  const containerStyle = {
    height,
    width,
  };

  return (
    <div className="u-flexColumn" style={containerStyle}>
      <VideoWrapper ref={videoRef} source={source} />
      <VideoControls ref={videoRef} />
    </div>
  );
}

VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;

export default VideoPlayer;
