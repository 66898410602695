import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  TextInput,
  Toggle,
} from '@makeably/creativex-design-system';
import { HiddenInput } from 'components/reusable/HiddenInput';
import styles from './RuleDefinitionEvaluationStrategy.module.css';

const criteriaOptionProps = {
  label: PropTypes.string,
  min: PropTypes.number,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};

const strategyProps = {
  criteriaOptions: PropTypes.arrayOf(PropTypes.shape(criteriaOptionProps)),
  label: PropTypes.string,
  value: PropTypes.string,
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  evaluationCriteria: PropTypes.object.isRequired,
  strategy: PropTypes.string.isRequired,
  strategyOptions: PropTypes.arrayOf(PropTypes.shape(strategyProps)).isRequired,
};

function renderBooleanCriteriaOption(criteriaOption, currentOptionValue, updateCriteria) {
  if (currentOptionValue === undefined) {
    updateCriteria(criteriaOption.name, criteriaOption.default);
  }
  return (
    <Toggle
      checked={currentOptionValue}
      label={criteriaOption.label}
      onChange={() => updateCriteria(criteriaOption.name, !currentOptionValue)}
    />
  );
}

function renderFloatCriteriaOption(criteriaOption, currentOptionValue = '', updateCriteria) {
  const toFloat = (text) => {
    const value = parseFloat(text);

    return value;
  };
  return (
    <TextInput
      label={criteriaOption.label}
      min={criteriaOption.min}
      required={criteriaOption.required}
      step="0.1"
      type="number"
      value={currentOptionValue}
      onChange={(value) => updateCriteria(criteriaOption.name, toFloat(value))}
    />
  );
}

function renderIntegerCriteriaOption(criteriaOption, currentOptionValue = '', updateCriteria) {
  const toInt = (text) => {
    const value = parseInt(text);

    return value;
  };
  return (
    <TextInput
      label={criteriaOption.label}
      min={criteriaOption.min}
      required={criteriaOption.required}
      step="1"
      type="number"
      value={currentOptionValue}
      onChange={(value) => updateCriteria(criteriaOption.name, toInt(value))}
    />
  );
}

function renderDefaultCriteriaOption(criteriaOption, currentOptionValue = '') {
  return (
    <TextInput
      label={criteriaOption.label}
      type="text"
      value={currentOptionValue}
      disabled
    />
  );
}

function renderCriteriaOption(criteriaOption, evaluationCriteria, updateCriteria) {
  let content;
  const currentOptionValue = evaluationCriteria[criteriaOption.name];
  switch (criteriaOption.type) {
    case 'boolean':
      content = renderBooleanCriteriaOption(
        criteriaOption,
        currentOptionValue,
        updateCriteria,
      );
      break;
    case 'float':
      content = renderFloatCriteriaOption(
        criteriaOption,
        currentOptionValue,
        updateCriteria,
      );
      break;
    case 'integer':
      content = renderIntegerCriteriaOption(
        criteriaOption,
        currentOptionValue,
        updateCriteria,
      );
      break;
    default:
      content = renderDefaultCriteriaOption(criteriaOption, currentOptionValue);
  }

  return (
    <div key={criteriaOption.name} className={styles.formGroup}>
      { content }
    </div>
  );
}

function RuleDefinitionEvaluationStrategy({
  evaluationCriteria,
  strategy,
  strategyOptions,
}) {
  const [criteria, setCriteria] = useState(evaluationCriteria);
  const [selectedStrategy, setSelectedStrategy] = useState(
    strategyOptions.find((option) => option.value === strategy),
  );

  const onStrategyChange = (strat) => {
    setSelectedStrategy(strat);
    setCriteria({});
  };

  const updateCriteria = (name, value) => {
    setCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className={styles.formGroupContainer}>
      <div className={styles.formGroup}>
        <Dropdown
          label="Evaluation Strategy"
          menuProps={{ size: 'large' }}
          options={strategyOptions}
          selected={selectedStrategy}
          size="large"
          onChange={onStrategyChange}
        />
      </div>
      { selectedStrategy.criteriaOptions
        .map((option) => renderCriteriaOption(option, criteria, updateCriteria)) }
      <HiddenInput name="guideline_detail[attribute_based][evaluation_strategy]" value={selectedStrategy.value} />
      <HiddenInput name="guideline_detail[attribute_based][evaluation_criteria]" value={JSON.stringify(criteria)} />
    </div>
  );
}

RuleDefinitionEvaluationStrategy.propTypes = propTypes;

export default RuleDefinitionEvaluationStrategy;
