// Helper functions for date manipulation

const MS_PER_HOUR = 3600000;
const MS_PER_DAY = 86400000;

// Expects Date object and returns month range in string form 'YYYY-MM-DD_YYYY-MM-DD'
export function getMonthRange(dateObject) {
  const year = dateObject.getUTCFullYear();
  const month = dateObject.getUTCMonth();
  const firstDay = new Date(Date.UTC(year, month, 1));
  const lastDay = new Date(Date.UTC(year, month + 1, 0));
  const firstDayString = firstDay.toISOString().split('T')[0];
  const lastDayString = lastDay.toISOString().split('T')[0];

  return `${firstDayString}_${lastDayString}`;
}
// Returns current month range in string form 'YYYY-MM-DD_YYYY-MM-DD'
export function getCurrentMonthRange() {
  return getMonthRange(new Date());
}
// Returns previous month range in string form 'YYYY-MM-DD_YYYY-MM-DD'
export function getPreviousMonthRange() {
  const lastDayOfLastMonth = (new Date()).setDate(0);
  return getMonthRange(lastDayOfLastMonth);
}

function getRemaining(startDate, endDate, msPerUnit, totalUnits) {
  const msDiff = endDate - startDate.getTime();
  const unitDiff = msDiff / msPerUnit;
  const unitsRemaining = totalUnits - unitDiff;

  return Math.round(unitsRemaining);
}

export function getHrsRemaining(startTime, endTime = Date.now(), totalHrs = 24) {
  return getRemaining(startTime, endTime, MS_PER_HOUR, totalHrs);
}

export function getDaysRemaining(startDate, endDate = Date.now(), totalDays = 7) {
  return getRemaining(startDate, endDate, MS_PER_DAY, totalDays);
}

function getDifference(startDate, endDate, msPerUnit) {
  const msDiff = endDate - startDate.getTime();
  const unitDiff = msDiff / msPerUnit;

  return Math.round(unitDiff);
}

export function getDaysDifference(startDate, endDate) {
  return getDifference(startDate, endDate, MS_PER_DAY);
}

export function secondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  let extraSeconds = Math.floor(seconds) % 60;
  extraSeconds = extraSeconds < 10 ? `0${extraSeconds}` : extraSeconds;
  return `${minutes}:${extraSeconds}`;
}
