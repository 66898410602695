import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Icon,
  Spinner,
  TextInput,
  Tooltip,
  findObjectByValue,
  toggleObject,
} from '@makeably/creativex-design-system';
import AuditFilterCollection from 'components/audit/audit_filter_collection.coffee';
import SearchableTable from 'components/organisms/SearchableTable';
import { addToast } from 'components/organisms/Toasts';
import { setItemElement } from 'utilities/itemElement';
import { track } from 'utilities/mixpanel';
import { settingsCsvExportsPath } from 'utilities/routes';
import { dateToString } from 'utilities/string';
import styles from './Exports.module.css';

const exportProps = PropTypes.shape({
  dateGenerated: PropTypes.string,
  dateRange: PropTypes.string,
  expired: PropTypes.bool,
  expiresIn: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  readyForDownload: PropTypes.bool,
  rowCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  url: PropTypes.string,
});

const dataOptionShape = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
});

const propTypes = {
  csvExports: PropTypes.arrayOf(exportProps).isRequired,
  dataOptions: PropTypes.arrayOf(dataOptionShape).isRequired,
  filterOptions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  maxSupportedRows: PropTypes.number.isRequired,
};

const tabOptions = [
  {
    key: 'active',
    name: 'Active',
  },
  {
    key: 'expired',
    name: 'Expired',
  },
];

const statuses = tabOptions.map(({ key }) => key);

const exportTypeOptions = [
  {
    label: 'Post Level',
    value: 'post',
  },
  {
    label: 'Placement Level',
    value: 'placement',
  },
];

function getHeaders(filterKey) {
  const allHeaders = [
    {
      label: 'Name (ID)',
      key: 'name',
    },
    {
      label: 'Date Generated',
      key: 'dateGenerated',
    },
    {
      label: 'Date Range',
      key: 'dateRange',
    },
    {
      label: 'Type',
      key: 'type',
    },
    {
      label: 'No. of Rows',
      key: 'rowCount',
    },
    {
      label: 'Expires In',
      key: 'expiresIn',
    },
    {
      label: '',
      key: 'download',
      filter: ['active'],
    },
  ];

  return allHeaders.filter((header) => (header.filter ? header.filter.includes(filterKey) : true));
}

function downloadCell({
  expired,
  readyForDownload,
  rowCount,
  url,
}, maxSupportedRows) {
  if (expired) return undefined;

  if (rowCount > maxSupportedRows) {
    return {
      element: (
        <Tooltip label="We&apos;re unable to generate your export due to the large data size of your selected timeframe. Please shorten the time range and try again.">
          <Icon color="orange" name="exclamationTriangle" />
        </Tooltip>
      ),
      value: 'error',
    };
  }

  if (readyForDownload) {
    return {
      element: (
        <a href={url} download>
          <Icon name="download" />
        </a>
      ),
      value: 'download',
    };
  }

  return {
    element: <Icon name="processing" />,
    value: 'download',
  };
}

function rowCountCell(value) {
  const hsh = { value };
  if (typeof value !== 'number') return hsh;

  return {
    ...hsh,
    format: 'number',
  };
}

function formattedExport(csvExport, maxSupportedRows) {
  return setItemElement({
    dateGenerated: { value: dateToString(csvExport.dateGenerated) },
    dateRange: { value: csvExport.dateRange },
    download: downloadCell(csvExport, maxSupportedRows),
    expiresIn: { value: csvExport.expiresIn },
    id: { value: csvExport.id },
    name: { value: csvExport.name },
    rowCount: rowCountCell(csvExport.rowCount),
    type: { value: csvExport.type },
  });
}

function formatExports(exports, maxSupportedRows) {
  return statuses.reduce((all, status) => {
    const current = exports.filter((e) => (status === 'expired' ? e.expired : !e.expired));

    return {
      ...all,
      [status]: current.map((exp) => formattedExport(exp, maxSupportedRows)),
    };
  }, {});
}

function renderDataOption(selected, data, onChange) {
  const isChecked = findObjectByValue(selected, data);

  const checkLabel = (
    <>
      <div>{ data.label }</div>
      <div className="t-caption-1">{ data.description }</div>
    </>
  );

  return (
    <div key={data.label} className="u-marginBelowSm">
      <Checkbox
        checked={isChecked}
        label={checkLabel}
        name={data.value}
        onChange={() => onChange(toggleObject(selected, data))}
      />
    </div>
  );
}

async function generateReport(filterFormId, name, reportType, selectedData) {
  const filterForm = document.getElementById(filterFormId);
  const filterFormData = new FormData(filterForm);
  filterFormData.append('name', name);
  filterFormData.append('export_type', reportType.value);

  selectedData.forEach(({ value }) => {
    filterFormData.append('data_options[]', value);
  });

  const response = await fetch(
    settingsCsvExportsPath(),
    {
      method: 'POST',
      body: filterFormData,
    },
  );

  return response.json();
}

function Exports({
  csvExports,
  dataOptions,
  filterOptions,
  maxSupportedRows,
}) {
  const [componentKey, setComponentKey] = useState(1);
  const [exportName, setExportName] = useState('');
  const [exportType, setExportType] = useState(exportTypeOptions[0]);
  const [exportsByStatus, setExportsByStatus] = useState(
    formatExports(csvExports, maxSupportedRows),
  );
  const [isBusy, setIsBusy] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const onGenerate = async () => {
    setIsBusy(true);

    const formId = filterOptions.formProps.id;
    const data = await generateReport(formId, exportName, exportType, selectedData);

    if (data.error) {
      addToast('Something went wrong, please try again.', {
        size: 'large',
        type: 'error',
      });
    } else {
      track('generate_csv_export');
      addToast("Your report is being processed. You'll receive an email in less than 24 hours when your CSV is ready to download.", { size: 'large' });
      setExportName('');
      setExportType(exportTypeOptions[0]);
      setExportsByStatus(formatExports(data.csvExports, maxSupportedRows));
      setSelectedData([]);
      setComponentKey((prevKey) => prevKey + 1);
    }

    setIsBusy(false);
  };

  const missingExportName = exportName === '';
  const hasTableData = Object.values(exportsByStatus).flat().length > 0;

  return (
    <div className={styles.page}>
      <Card className={styles.selectionCard}>
        <h5 className="u-marginBelowLg">Configure Export</h5>
        <div className="u-flexRow u-marginBelowMd">
          <div className="u-marginRightMd">
            <TextInput
              label="Export Name"
              name="export_name"
              size="medium"
              value={exportName}
              required
              onChange={(value) => setExportName(value)}
            />
          </div>
          <Dropdown
            label="Export Type"
            menuProps={{ size: 'medium' }}
            options={exportTypeOptions}
            selected={exportType}
            size="medium"
            onChange={(option) => setExportType(option)}
          />
        </div>
        <div>
          <div className="t-caption-1">Column Groups</div>
          <div>
            { dataOptions.map((data) => (
              renderDataOption(selectedData, data, setSelectedData)
            )) }
          </div>
        </div>
        <h5 className="u-marginAboveLg u-marginBelowMd">Filter by</h5>
        <div className={styles.filteringOverride}>
          <AuditFilterCollection
            key={`filters_${componentKey}`}
            filtersForExports
            startWithFilter
            vertical
            {...filterOptions}
          />
        </div>
        <div className="u-marginAbove u-flexRow u-justifyFlexEnd">
          <Button
            disabled={isBusy || missingExportName}
            label="Generate"
            variant="primary"
            onClick={onGenerate}
          />
        </div>
        { isBusy && (
          <div className={styles.busy}>
            <Spinner />
          </div>
        ) }
      </Card>
      { hasTableData && (
        <div data-testid="exports:reports">
          <SearchableTable
            key={`table_${componentKey}`}
            canDownloadCsv={false}
            getHeaders={(key) => getHeaders(key)}
            groupedItems={exportsByStatus}
            tabDetails={tabOptions}
          />
        </div>
      ) }
    </div>
  );
}

Exports.propTypes = propTypes;

export default Exports;
