import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  MoreButton,
  Spinner,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import ScoreModal from 'components/scores/modal/ScoreModal';
import ScoreDetails from 'components/scores/ScoreDetails';
import { getStepContent } from 'components/scores/show/OverviewSteps';
import GuidelineTable from 'components/scores/table/GuidelineTable';
import { scoreDataScorePath } from 'utilities/routes';
import styles from './Overview.module.css';

const propTypes = {
  scoreId: PropTypes.number.isRequired,
};

function getStateCounts(countsArray) {
  let stateCounts = { total: 0 };
  countsArray.forEach((stateCount) => {
    const {
      count,
      state,
    } = stateCount;
    stateCounts = {
      ...stateCounts,
      [state]: count,
      total: stateCounts.total + count,
    };
  });
  return stateCounts;
}

function Overview({ scoreId }) {
  const [scoreData, setScoreData] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetchProps() {
      const response = await fetch(scoreDataScorePath(scoreId, { type: 'overview' }));

      if (response.ok) {
        const data = await response.json();

        setScoreData(data);
      } else {
        addToast('Something went wrong, please reload to try again.', {
          size: 'large',
          type: 'error',
        });
      }
    }

    fetchProps();
  }, [scoreId]);

  if (!scoreData) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  const {
    canEditScore,
    createdAt,
    description,
    guidelines,
    isDefault,
    label,
    name,
    qualityRate,
    qualitySpendRate,
    scoreDenyList,
    scoreType,
    status,
    updatedAt,
    counts,
  } = scoreData;

  const guidelineCount = [...new Set(guidelines.map(({ id }) => id))].length;

  const moreOptions = [
    {
      label: 'Edit Score Details',
      onClick: () => {
        setShowModal(true);
      },
    },
  ];

  const moreButton = canEditScore && (
    <MoreButton options={moreOptions} />
  );

  return (
    <>
      <Card className="t-body-1">
        <ScoreDetails
          createdAt={createdAt}
          description={description}
          guidelineCount={guidelineCount}
          id={scoreId}
          isDefault={isDefault}
          label={label}
          scoreType={scoreType}
          stateCounts={getStateCounts(counts)}
          status={status}
          titleContent={moreButton}
          updatedAt={updatedAt}
        />
        { guidelineCount > 0 && (
          <>
            <Divider margin />
            <GuidelineTable
              guidelines={guidelines}
            />
          </>
        ) }
      </Card>
      <ScoreModal
        description={description || ''}
        getStepContent={getStepContent}
        isOpen={showModal}
        label={label}
        name={name}
        qualityRate={qualityRate}
        qualitySpendRate={qualitySpendRate}
        scoreDenyList={scoreDenyList}
        scoreId={scoreId}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}

Overview.propTypes = propTypes;

export default Overview;
